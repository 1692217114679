export const root = '/'
export const profile = '/profile'
export const auth = '/auth'
export const authSignUp = '/auth/sign-up'
export const authForgotPassword = '/auth/forgot-password'
export const jobPosts = '/jobs'
export const findJobs = '/find-jobs'
export const findHelp = '/find-help'
export const myJobs = '/my-jobs'
export const myFavourites = '/my-favourites'
export const myRequests = '/my-requests'
export const trackNow = '/track-now'
export const notifications = '/notifications'
export const jobDescription = '/:jobId'
export const appliedServiceProviders = '/applied-service-providers'
export const freelancerProfile = '/:freelancerId'
export const requestId = '/:requestId'
export const forgotIdPass = '/forgot-pass'
export const signIn = '/sign-in'
export const signUp = '/sign-up'
export const termsCond = '/terms-conditions'
export const verifyEmail = '/verify-email'
export const verify = '/verify'
export const verifyPassword = '/restore-password'
export const settings = '/settings'
export const ratingReview = '/rating-review'
export const ratingProfile = '/review-profile'
export const subscriptions = '/subscriptions'
export const posted = '/posted'
export const applied = '/applied'
export const booked = '/booked'
export const bookingRequests = '/booking-requests'
export const hiringRequests = '/hiring-requests'
export const job = '/job'
export const serviceProvider = '/service-provider'
export const listView = '/list'
export const mapView = '/map'
export const chats = '/chats'
export const privacy = '/privacy'
export const paymentsTransactions = '/payments-transactions'
export const paymentSetup = '/payment-setup'
export const earnings = '/earnings'
export const paymentsHistory = '/payments'
export const withdrawals = '/withdrawals'
export const withdrawalMoney = '/withdrawal-money'
