import { compose } from 'redux'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import { withPayments } from 'Components/hoc'

import { loadSubscriptionPlans, loadPtsCost } from 'Redux/actions/subscription'
import { fetchUser } from 'Redux/actions/user'

import { getProfile } from 'Redux/selectors/user'
import { getPlans } from 'Redux/selectors/subscriptions'

import Component from './Subscriptions'
import { withNamespaces } from 'react-i18next'

const actions = {
  onLoadPlans: loadSubscriptionPlans,
  onLoadPrice: loadPtsCost,
  onFetchUser: fetchUser,
}

const selector = createStructuredSelector({
  user: getProfile,
  plans: getPlans
})

export default compose(
  withPayments,
  withNamespaces(['subscriptionScreen', 'landingPage']),
  connect(selector, actions)
)(Component)
