import styled from 'styled-components'
import { fontFamily, sizes, fontSize } from 'Constants'

/* Navigation
/* ## ## ## ## ## ## ## ## ## ## ## ## ## ## ## ## ## ## ## ## ## ## ## ## */

export const NavigationWrapper = styled.div`
  & > ul {
    list-style-type: none;
    display: flex;
    padding: 0;
    margin: 0;
    font-size: ${fontSize.small};
    font-family: ${fontFamily};
    padding-bottom: 5px;
    & > li {
      margin: 0 10px;
      @media (max-width: ${sizes.desktop}px) {
        margin: 0 5px;
      }
      @media (max-width: ${sizes.tablet}px) {
        font-size: ${fontSize.h4};
        font-family: ${fontFamily};
        text-align: center;
      }
    }
    @media (max-width: ${sizes.tablet}px) {
      flex-direction: column;
      padding-top: 4em;
    }
  }
`

export const NavLink = styled.span`
cursor: pointer;
  text-decoration: none;
  padding-bottom: 10px;
  font-size: ${fontSize.h5};
  font-family: ${fontFamily};
  display: inline-block;
  position: relative;
  text-align: center;
  color: #fff;
  line-height: 1;
  opacity: ${props => (props.active ? '1' : '0.8')}
  font-weight: ${props => (props.active ? '900' : '500')};
  &:after {
    content: '';
    position: absolute;
    border-bottom: 3px solid transparent;
    width: 50%;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    border-bottom: ${props =>
      props.active ? '3px solid #fff' : '3px solid transparent'};
  }
  &:hover {
    opacity: 1;
  }
  @media (max-width: ${sizes.desktop}px) {
    font-size: ${fontSize.h5};
    font-family: ${fontFamily};
  }
  @media (max-width: ${sizes.phone}px) {
    padding-bottom: 0;
  }
  @media (max-width: ${sizes.tablet}px) {
    padding-bottom: 20px;
  }

`
