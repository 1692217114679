import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { withNamespaces } from 'react-i18next'

import { Modal, DatePicker, InputWrapper, SelectCurrency } from 'Components/ui'

import {
  Container,
  DatePickerWrapper,
  Header,
  Title,
  BudgetWrapper,
  Label,
  Input,
  CurrencyContainer,
  ButtonsWrapper,
  SubmitButton,
  CancelButton,
  Row,
  Text,
  styles,
  Description
} from './style'
import Switch from 'react-switch'
import { getBudget } from 'Constants/payment'
import { toast } from 'react-toastify'
// import { getMaxDate } from 'Helpers/time'

class DateModal extends PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      date: new Date(),
      budget: props.budget || 0,
      currency: props.currency || 'USD',
      isRecurring: false,
      rate: 1,
      budgetConvert: props.budget || 0
    }
  }

  componentDidMount() {
    const { onGetRatio } = this.props
    const { currency } = this.state
    if (currency !== 'USD') {
      onGetRatio(currency, rate => this.setState({ rate }))
    }
  }

  componentDidUpdate(prevProps) {
    const { budget, currency } = this.props
    if (prevProps.budget !== budget || prevProps.currency !== currency) {
      this.setState({
        budget,
        currency
      })
    }
  }

  changeDate = date => this.setState({ date })

  handleChangeBudget = ({ target: { value } }) =>
    this.setState({ budget: value })

  handleChangeCurrency = currency => {
    if (currency === this.state.currency) return this.setState({ currency })

    const { onGetRatio } = this.props
    onGetRatio(currency, rate => this.setState({ currency, rate }))
  }

  handleSubmit = () => {
    const { onSubmit, t } = this.props
    const { date, budget, currency, isRecurring, rate } = this.state

    if (getBudget(budget, rate, currency, 'number') < 10) {
      return toast.warn(t('budgetDescription'))
    }

    onSubmit({
      date,
      budget: getBudget(budget, rate, currency, 'number'),
      currency,
      isRecurring,
      budgetMyCountry: Number(budget)
    })
  }

  handleChangeRecurring = () =>
    this.setState({ isRecurring: !this.state.isRecurring })

  render() {
    const { isVisible, onClose, t, isRequest, isShowDescription } = this.props
    const { date, budget, currency, isRecurring, rate } = this.state

    return (
      <Modal isShow={isVisible} onRequestClose={onClose}>
        <Header>
          <Title>{t('serviceProvider:hireNow')}</Title>
        </Header>
        {isShowDescription && (
          <Description>{t('jobDetail:EmployerHiringRequestTip')}</Description>
        )}
        <Container>
          <DatePickerWrapper>
            <Label>{t('myJobsDetail:appointmentScheduledFor')}:</Label>
            <DatePicker
              data-lpignore="true"
              dateFormat="MMMM d, yyyy h:mm aa"
              handleChange={this.changeDate}
              minDate={new Date()}
              showTimeSelect
              timeCaption={t('time')}
              timeFormat="HH:mm"
              timeIntervals={30}
              value={date}
              // maxDate={getMaxDate()}
            />
          </DatePickerWrapper>
          <Row>
            <Text flex={1}>{t('profilePage:recurring')}</Text>
            <Switch
              checked={isRecurring}
              checkedIcon={null}
              uncheckedIcon={null}
              onChange={this.handleChangeRecurring}
            />
          </Row>
          <BudgetWrapper>
            <InputWrapper>
              <Label htmlFor="budget">{t('budget')}</Label>
              <BudgetWrapper>
                <Input
                  data-lpignore="true"
                  id="budget"
                  min="0"
                  type="number"
                  value={budget}
                  onChange={this.handleChangeBudget}
                />
                <CurrencyContainer>
                  <SelectCurrency
                    value={currency}
                    onSelect={this.handleChangeCurrency}
                  />
                </CurrencyContainer>
              </BudgetWrapper>
            </InputWrapper>
          </BudgetWrapper>
          <Row top={35}>
            <Input
              value={getBudget(budget, rate, currency)}
              style={styles.budgetConvert}
            />
            <Input value="USD" style={styles.currency} />
          </Row>
          <Row>
            <Text>{t('budgetDescription')}</Text>
          </Row>
        </Container>
        <ButtonsWrapper>
          <CancelButton disabled={isRequest} onClick={onClose}>
            {t('cancel')}
          </CancelButton>
          <SubmitButton disabled={isRequest} onClick={this.handleSubmit}>
            {t('serviceProvider:hireNow')}
          </SubmitButton>
        </ButtonsWrapper>
      </Modal>
    )
  }
}

DateModal.propTypes = {
  budget: PropTypes.number,
  currency: PropTypes.string,
  isRequest: PropTypes.bool,
  isVisible: PropTypes.bool,
  t: PropTypes.func,
  user: PropTypes.object,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  isShowDescription: PropTypes.bool,
  onGetRatio: PropTypes.func
}

export default withNamespaces([
  'jobPost',
  'findHelp',
  'myJobsDetail',
  'serviceProvider'
])(DateModal)
