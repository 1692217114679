import React, { PureComponent, Fragment } from 'react'
import i18n from 'i18next'
import _ from 'lodash'
import LANGUAGES from 'Constants/lang'
import USER_LANG from 'Constants/userLang'
import { LangList, LangLi, LangLink, LangImg, LangLabel } from './styles'

export default class Languages extends PureComponent {
  state = {
    langSelect: false,
    langArray: [
      {
        flag: null,
        type: null
      }
    ]
  }

  componentDidMount() {
    let localLang = localStorage.getItem('lang')

    if (localLang === null) {
      localStorage.setItem('lang', USER_LANG)

      i18n.changeLanguage(USER_LANG)

      const newArrCoutryCodes = _.orderBy(
        Object.values(LANGUAGES),
        lang => lang.code === USER_LANG,
        'desc'
      )

      this.setState({
        langArray: newArrCoutryCodes
      })
    } else {
      localLang = LANGUAGES[localLang] ? localLang : 'en'
      localStorage.setItem('lang', localLang)

      i18n.changeLanguage(localLang)

      const newArrCoutryCodes = _.orderBy(
        Object.values(LANGUAGES),
        lang => lang.code === localLang,
        'desc'
      )

      this.setState({
        langArray: newArrCoutryCodes
      })
    }

    document.body.addEventListener('click', this.closeDropDownOnClickOutside)
  }

  componentWillUnmount() {
    document.body.removeEventListener('click', this.closeDropDownOnClickOutside)
  }

  handleSelectLang = () => {
    this.setState({
      langSelect: !this.state.langSelect
    })
  }

  handleSetLang = value => {
    const { onSetPreferredLanguage } = this.props
    const { langSelect } = this.state
    i18n.changeLanguage(value.code)
    const newArrCoutryCodes = _.orderBy(
      Object.values(LANGUAGES),
      lang => lang.code === value.code,
      'desc'
    )
    onSetPreferredLanguage(value.code)

    this.setState({
      langSelect: !langSelect,
      langArray: newArrCoutryCodes
    })

    localStorage.setItem('lang', value.code)
  }

  closeDropDownOnClickOutside = ({ target }) => {
    const langMenu = target.closest('.lang__list')
    if (!langMenu) {
      this.setState({
        langSelect: false
      })
    }
  }

  render() {
    const { t, isAuth, view } = this.props
    const { langSelect, langArray } = this.state
    const type = LANGUAGES[i18n.language]?.type || LANGUAGES.en.type
    const flag = LANGUAGES[i18n.language]?.flag || LANGUAGES.en.flag

    const selectedLang = !langSelect && (
      <LangLi className="lang__li" onClick={this.handleSelectLang}>
        <LangLink>
          <LangImg alt={type} src={flag}/>
          <LangLabel>{t(type)}</LangLabel>
        </LangLink>
      </LangLi>
    )

    const langList = langArray.map(value => {
      return (
        <LangLi
          className="lang__li"
          key={value.id}
          onClick={this.handleSetLang.bind(this, value)}
        >
          <LangLink>
            <LangImg alt={value.type} src={value.flag} />
            <LangLabel select={langSelect}>{t(value.type)}</LangLabel>
          </LangLink>
        </LangLi>
      )
    })

    return (
      <Fragment>
        <LangList
          className="lang__list"
          isAuth={isAuth}
          select={langSelect}
          view={view}
        >
          {langSelect ? langList : null}
          {selectedLang}
        </LangList>
      </Fragment>
    )
  }
}
