import styled from 'styled-components'
import { sizes } from 'Constants'

export const ShowVideo = styled.iframe`
  width: 560px;
  height: 315px;
  @media (max-width: ${sizes.phone}px) {
    max-width: 100%;
    max-height: 100%;
  }
`

export const DonateButton = styled.img`
  position: absolute;
  top: 80px;
  left: 20px;
  width: 300px;
  cursor: pointer;
  z-index: 999;
  @media (max-width: ${sizes.phone}px) {
    width: 150px;
  }
`