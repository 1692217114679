import styled from 'styled-components'
import DatePicker from 'react-datepicker'
import { fontFamily, fontSize } from 'Constants'

export const Picker = styled(DatePicker)`
  width: 100%;
  border: 2px solid #eee;
  border-radius: 5px;
  font-family: ${fontFamily};
  background: #fff;
  padding: 10px;
  font-size: ${fontSize.default};
  &:-webkit-autofill {
    -webkit-text-fill-color: #fff;
    -webkit-box-shadow: 0 0 0 1000px #5da6ff inset;
    border: none;
  }
`
