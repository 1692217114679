import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { fontFamily, sizes, fontSize } from 'Constants'

export const Background = styled.div`
  min-height: 100vh;
  padding: 30px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: linear-gradient(
    to bottom,
    #1e5799 0%,
    #2989d8 0%,
    #207cca 15%,
    #fff 100%
  );
  & a {
    text-decoration: none;
  }
`

export const Registered = styled.sup`
  color: ${props => props.theme.colors.defaultFont};
  font-size: ${fontSize.h4};
  font-family: ${fontFamily};
`

export const Logo = styled.h2`
  color: ${props => props.theme.colors.defaultFont};
  font-size: ${fontSize.h1};
  font-family: ${fontFamily};
  font-weight: bold;
  margin: 0;
  text-align: center;
`

export const SubTitle = styled.p`
  color: ${props => props.theme.colors.defaultFont};
  font-size: ${fontSize.h3};
  font-family: ${fontFamily};
  margin: 0;
`
export const Form = styled.form`
  background: ${props => props.theme.colors.defaultFont};
  width: 450px;
  max-width: 90%;
  padding: 2em 1em;
  margin-top: 1em;
  border-radius: 5px;
  box-shadow: 0 30px 30px rgba(0, 0, 0, 0.1), 0 10px 10px rgba(0, 0, 0, 0.1);
  @media (max-width: ${sizes.phone}px) {
    width: 90%;
  }
`

export const ArrowBack = styled(Link)`
  cursor: pointer;
  position: absolute;
  left: 0;
`
export const FormTitle = styled.h2`
  font-size: ${fontSize.h2};
  font-family: ${fontFamily};
  display: block;
  margin: 0;
  padding-bottom: 0.5em;
  text-align: center;
  position: relative;
`

export const InputWrapper = styled.div`
  position: relative;
  width: 100%;
  & > svg {
    position: absolute;
    right: 15px;
    top: 50%;
    cursor: pointer;
    & > path {
      fill: ${props => props.theme.colors.defaultBlue};
    }
  }
  @media only screen and (max-width: ${sizes.tablet}px) {
    & > svg {
      top: 46%;
    }
  }
`

export const Label = styled.label`
  font-size: ${fontSize.h5};
  font-family: ${fontFamily};
  position: relative;
  display: flex;
  align-items: center;
  line-height: 22px;
  flex-basis: 50%;

  @media (max-width: ${sizes.tablet}px) {
    font-size: ${fontSize.small};
  }
`

export const AuthInput = styled.input`
  width: 100%;
  padding: 10px;
  border: 2px solid #eee;
  border-radius: 5px;
  min-height: 43px;
  font-size: ${fontSize.default};
  font-family: ${fontFamily};
  outline: none;
  margin: 10px 0 20px 0;
  &:focus {
    border: 2px solid #9bc2e3;
  }
  &:-webkit-autofill {
    -webkit-text-fill-color: #fff;
    -webkit-box-shadow: 0 0 0 1000px #5da6ff inset;
    border: none;
  }
`

export const ChekboxInput = styled.input`
  width: auto;
  opacity: 0;
  position: absolute;
`

export const CheckBoxSpan = styled.span`
  position: relative;
  cursor: pointer;
  height: 20px;
  width: 20px;
  border: ${props =>
    props.toggle ? '2px solid transparent' : '2px solid #eee'}
  background: ${props => (props.toggle ? '#2196F3' : 'transparent')}
  border-radius: 3px;
  &:after {
    content: "";
    position: absolute;
    display: block;
    left: 5px;
    top: 0;
    width: 5px;
    height: 10px;
    border: ${props => (props.toggle ? 'solid #fff' : 'solid transparent')}
    border-width: 0 3px 3px 0;
    transform: rotate(45deg);
  }
`
export const CheckBoxText = styled.p`
  margin: 0;
  padding-left: 10px;
  cursor: pointer;
  font-size: ${fontSize.small};
`
export const AuthFormWrapper = styled.div`
  width: 80%;
  margin: 0 auto;
`

export const BtnSubmit = styled.button`
  margin-top: 1.3em;
  outline: none;
  width: ${props => (props.width ? props.width : '100%')};
  border: none;
  background: ${props =>
    props.disabled
      ? 'linear-gradient(-75deg, #f0f0f0, #cfcfcf)'
      : 'linear-gradient(to right, #459aee 0%, #3ad6ff 99%)'};
  padding: 10px;
  border-radius: 100px;
  color: ${props => (props.disabled ? '#989a9c' : '#fff')};
  font-size: ${fontSize.h5};
  font-family: ${fontFamily};
  text-transform: uppercase;
  font-weight: bold;
  cursor: pointer;
  &:hover {
    background: ${props =>
      props.disabled
        ? 'linear-gradient(-120deg, #cfcfcf, #f0f0f0)'
        : 'linear-gradient(to right, #3ad6ff 0%, #459aee 99%)'};
  }
  & > img {
    height: 0.8em;
  }
  @media (max-width: ${sizes.tablet}px) {
    font-size: ${fontSize.default};
  }
  @media (max-width: ${sizes.phone}px) {
    width: 80%;
  }
`

export const CancelButton = styled(BtnSubmit)`
  background: linear-gradient(-75deg, #f0f0f0, #cfcfcf);
  &:hover {
    background: linear-gradient(-120deg, #cfcfcf, #f0f0f0);
  }
`

export const Error = styled.span`
  position: absolute;
  font-size: ${fontSize.small};
  font-family: ${fontFamily};
  display: block;
  color: red;
  bottom: 0;
  right: 0;
`

export const ForgotWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const ForgetLink = styled(Link)`
  color: #3a83cb;
  font-size: ${fontSize.small};
  font-family: ${fontFamily};
  text-decoration: none;
  margin-left: 7px;
`

export const Col4 = styled.div`
  flex: 0 0 33.33333333333333%;
  @media (max-width: ${sizes.phone}px) {
    flex: 0 0 100%;
  }
`

export const Col8 = styled.div`
  flex: 0 0 66.66666666666666%;
  @media (max-width: ${sizes.phone}px) {
    flex: 0 0 calc(100% - 95px);
  }
`
