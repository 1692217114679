import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { Lang, Search } from 'Components/blocks'
import { signUp, root, signIn } from 'Routes/routesConstants'

import {
  Profile,
  Messages,
  Navigation,
  Notifications,
  PostJobBtn
} from 'Components/ui'

import {
  Wrapper,
  SearchLine,
  Pts,
  NavLine,
  IconGroup,
  LeftPart,
  Logo,
  Registered,
  RightPart,
  SignInLink,
  JoinUsNav,
  RightPartDoted,
  Head,
  Wrap,
  HeaderWrap,
  Div,
  MediaDesktop,
  MediaTablet,
  UserInfo
} from './styles'

const Header = ({
  t,
  isMessage,
  view,
  postJob,
  location,
  handleSearchHelp,
  handleSearchJob,
  notifications,
  searchValue,
  user,
  isUnreadMessage,
  points
}) => {
  const landingHeader = view && (
    <>
      <Head>
        <Wrapper view={view}>
          <Wrap view={view}>
            <HeaderWrap>
              <LeftPart>
                <Logo to={root}>
                  SpotJobs<Registered>&reg;</Registered>
                </Logo>
              </LeftPart>
              <MediaTablet>
                <RightPart>
                  <Lang view={view} />
                  <SignInLink to={signIn}>{t('landingPage:signIn')}</SignInLink>
                  <JoinUsNav to={signUp}>
                    <span>{t('landingPage:joinFree')}</span>
                  </JoinUsNav>
                </RightPart>
              </MediaTablet>
              <RightPartDoted>&#9776;</RightPartDoted>
            </HeaderWrap>
          </Wrap>
        </Wrapper>
      </Head>
    </>
  )

  const appHeader = !view && (
    <>
      <Head>
        <Wrapper view={view}>
          <Wrap view={view}>
            <Div>
              <HeaderWrap>
                <Logo to={root}>
                  SpotJobs<Registered>&reg;</Registered>
                </Logo>
                <MediaTablet>
                  <IconGroup className="IconGroup">
                    <SearchLine>
                      <Search
                        value={searchValue}
                        onSearchHelp={handleSearchHelp}
                        onSearchJob={handleSearchJob}
                      />
                      <MediaDesktop>
                        <Pts>
                          {/* {points || 0} {t('subscriptionScreen:points')} */}
                          {user.ptsAmount || 0} {t('subscriptionScreen:points')}
                        </Pts>
                      </MediaDesktop>
                      <Messages isActive={isUnreadMessage[user?._id]} />
                      <Notifications
                        isActive={notifications.list.some(el => !el.read)}
                      />
                    </SearchLine>
                  </IconGroup>
                </MediaTablet>
                <MediaTablet>
                  <UserInfo>
                    <Lang view={view} />
                    <Profile />
                  </UserInfo>
                </MediaTablet>
              </HeaderWrap>
              <MediaTablet>
                <NavLine>
                  <Navigation isActive={location.pathname} />
                </NavLine>
              </MediaTablet>
            </Div>
            <PostJobBtn postJob={postJob} />
            <RightPartDoted>&#9776;</RightPartDoted>
          </Wrap>
        </Wrapper>
      </Head>
    </>
  )

  return (
    <Fragment>
      {landingHeader}
      {appHeader}
    </Fragment>
  )
}

Header.propTypes = {
  handleSearchHelp: PropTypes.func,
  handleSearchJob: PropTypes.func,
  isMessage: PropTypes.bool, // Required?
  isNotification: PropTypes.bool,
  searchValue: PropTypes.string,
  view: PropTypes.bool,
  isUnreadMessage: PropTypes.object
}

Header.defaultProps = {
  isMessage: false,
  isNotification: true
}

export default Header
