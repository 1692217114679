import PropTypes from 'prop-types'
import React, { Component } from 'react'
import _ from 'lodash'

import {
  BreadCrumbs,
  BurgerMenu,
  Header,
  Ad,
  Footer,
  SubscriptionPackage,
  SubscriptionPremiumPuck,
  ExtraPoints,
  ProgressBar
} from 'Components/blocks'
import { PageWrapper, HalfWidthWrapper, ContentWrapper } from 'Components/ui'

import {
  CloudWrap,
  Section,
  MainWrap,
  Pts,
  LeftPart,
  ExtraPointsWrapper
} from './styles'
import { getBC } from './config'

class Subscriptions extends Component {
  state = {
    points: 25,
    isRequest: true
  }

  componentDidMount() {
    const { onLoadPlans, onLoadPrice, onFetchUser } = this.props

    if (window.location.pathname === '/subscriptions' && window.location.search) {
      return window.location.replace(`${window.location.origin}${window.location.pathname}`)
    }
    onLoadPrice(() => this.setState({ isRequest: false }))
    onLoadPlans(() => this.setState({ isRequest: false }))
    onFetchUser(() => this.setState({ isRequest: false }))
  }

  getPlanCost = planType => {
    const { price, plans } = this.props

    return (_.get(plans, `${planType}.val`, 0) * price).toFixed(2)
  }

  getTotalPrice = () => {
    const { price } = this.props
    const { points } = this.state

    return (points * price).toFixed(2)
  }

  handleIncrement = () =>
    this.setState(
      state =>
          state.points === 25 ? ({ points: state.points + 25 })
        : state.points === 50 ? ({ points: state.points + 50 })
        : state.points === 100 ? ({ points: state.points + 400 })
        : ({ points: state.points })
    )

  handleDecrement = () =>
    this.setState(
      state =>  ( state.points > 0 &&
        state.points === 25 ? ({ points: state.points - 0 })
      : state.points === 50 ? ({ points: state.points - 25 })
      : state.points === 100 ? ({ points: state.points - 50 })
      : state.points === 500 ? ({ points: state.points - 400 })
      : ({ points: state.points })
    ))

  handleChangePoints = ({ target: { value } }) =>
    this.setState({ points: Number.parseInt(value) })

  renderExtraPoints = () => {
    const { onBuyPoints } = this.props
    const { points } = this.state
    return (
      <ExtraPointsWrapper>
        <ExtraPoints
          totalPrice={this.getTotalPrice()}
          value={points}
          onBuyClick={() => onBuyPoints(points)}
          onChange={this.handleChangePoints}
          onDecrement={this.handleDecrement}
          onIncrement={this.handleIncrement}
        />
      </ExtraPointsWrapper>
    )
  }

  render() {
    const { t, user, plans, onBuyPlan, isPaymentRequest } = this.props

    const { subscriptionType } = _.get(user, 'activeSubscription', {})
      ? _.get(user, 'activeSubscription', {})
      : 'monthly'
    const isYearlyPremium = subscriptionType === 'yearly'

    return (
      <PageWrapper>
        <ProgressBar percent={isPaymentRequest ? 1 : 100} />
        <BurgerMenu user={user} />
        <Header postJob={true} />
        <BreadCrumbs data={getBC(t)} />
        <Section>
          <ContentWrapper>
            <Ad />
            <HalfWidthWrapper>
              <h2>{t('subscriptions')}</h2>
              <CloudWrap>
                <span>{t('youCurrentlyHave')}</span>
                <Pts>
                  {user.ptsAmount || 0} {t('points')}
                </Pts>
              </CloudWrap>
              <MainWrap isYearlyPremium={isYearlyPremium}>
                {!user.isPremium && (
                  <SubscriptionPackage
                    cost={this.getPlanCost('yearly')}
                    isVisible={!isYearlyPremium}
                    price={_.get(plans, 'yearly.val', 0)}
                    title={t('yearly')}
                    type="yearly"
                    onBuyClick={() => onBuyPlan(plans.yearly)}
                    onUsePointsClick={() =>
                      onBuyPlan(plans.yearly, { forPoints: true })
                    }
                  />
                )}

                <LeftPart>
                  {user.isPremium ? (
                    <>
                      <SubscriptionPremiumPuck
                        planType={subscriptionType}
                        points={user.ptsAmount}
                        expiryDate={user?.activeSubscription?.subscriptionExpirationDate}
                      />
                      {this.renderExtraPoints()}
                    </>
                  ) : (
                    <>
                      <SubscriptionPackage
                        cost={this.getPlanCost('monthly')}
                        price={_.get(plans, 'monthly.val', 0)}
                        title={t('monthly')}
                        onBuyClick={() => onBuyPlan(plans.monthly)}
                        onUsePointsClick={() =>
                          onBuyPlan(plans.monthly, { forPoints: true })
                        }
                      />
                      {this.renderExtraPoints()}
                    </>
                  )}
                </LeftPart>
              </MainWrap>
            </HalfWidthWrapper>
            <Ad />
          </ContentWrapper>
        </Section>
        <Footer black={true} isAuth={user} />
      </PageWrapper>
    )
  }
}

export default Subscriptions

Subscriptions.propTypes = {
  isPaymentRequest: PropTypes.bool,
  plans: PropTypes.object,
  price: PropTypes.number,
  t: PropTypes.func,
  user: PropTypes.object,
  onBuyPlan: PropTypes.func.isRequired,
  onBuyPoints: PropTypes.func.isRequired,
  onLoadPlans: PropTypes.func.isRequired,
  onLoadPrice: PropTypes.func.isRequired,
  onFetchUser: PropTypes.func,
}
