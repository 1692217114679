import React from 'react'
import { withNamespaces } from 'react-i18next'
import { withRouter } from 'react-router-dom'
import memoize from 'memoize-one'

import { NavigationWrapper, NavLink } from './styles'
import { getLinks } from './config'

const Nav = props => {
  const { t, isActive, history } = props
  const isCurrent = isActive === undefined ? '' : isActive

  const links = getLinks(t)

  const handleClick = ({ route }, isActive) => {
    if (isActive) history.replace(route)
    else history.push(route)
  }

  return (
    <NavigationWrapper>
      <ul>
        {links.map((link, key) => {
          const isActive = +isCurrent.includes(link.route)
          return (
            <li key={key}>
              <NavLink
                active={isActive}
                onClick={memoize(() => handleClick(link, isActive))}
              >
                {link.title}
              </NavLink>
            </li>
          )
        })}
      </ul>
    </NavigationWrapper>
  )
}

export const Navigation = withRouter(withNamespaces('headerBlock')(Nav))
