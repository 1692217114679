import styled from 'styled-components'
import { icons } from 'Assets/icons'

export const Section = styled.section`
  padding: 30px 0;
`

export const Pts = styled.span`
  font-weight: 800;
  text-transform: lowercase;
`

export const CloudWrap = styled.div`
  background: no-repeat url(${icons.cloud});
  min-height: 135px;
  min-width: 250px;
  padding: 20px 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  flex-direction: column;
`

export const MainWrap = styled.div`
  margin: 50px 0;
  display: flex;
  width: 100%;
  justify-content: ${p => (p.isYearlyPremium ? 'center' : 'space-between')};
  align-items: flex-start;
`

export const LeftPart = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 48%;
  justify-content: space-between;
`

export const ExtraPointsWrapper = styled.div`
  margin-top: 10px;
`
