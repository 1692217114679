import memoize from 'memoize-one'
import {
  findHelp,
  findJobs,
  jobPosts,
  myFavourites,
  myJobs,
  myRequests,
  trackNow
} from 'Routes/routesConstants'

export const getLinks = memoize(t => [
  { route: findJobs, title: t('menu.findJob') },
  { route: jobPosts, title: t('menu.postJob') },
  { route: findHelp, title: t('menu.findHelp') },
  { route: myJobs, title: t('menu.myJobs') },
  { route: myFavourites, title: t('menu.myFavourites') },
  { route: myRequests, title: t('menu.myRequests') },
  { route: trackNow, title: t('menu.trackNow') }
])
